<template>
  <img
    :class="
      !invert
        ? ''
        : theme.global.current.value.dark
          ? 'logo-image-invert-dark'
          : 'logo-image-invert-light'
    "
    :src="imgSrc(value.image)"
    :style="{
      opacity,
      maxWidth: `${width}px`,
      width: pinWidth ? `${width}px` : undefined
    }"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useTheme } from 'vuetify'
import { createImgSrc } from '#root/lib/img'

const props = defineProps({
  fitHeight: {
    default: 50,
    type: Number
  },
  invert: {
    default: false,
    type: Boolean
  },
  opacity: {
    default: 1,
    type: Number
  },
  pinWidth: {
    default: false,
    type: Boolean
  },
  value: {
    required: true,
    type: Object
  }
})

const theme = useTheme()
const imgSrc = createImgSrc()
const aspectRatio = computed(() => {
  return props.value.width / props.value.height
})
const width = computed(() => {
  return props.fitHeight * aspectRatio.value * props.value.scale
})
</script>

<style>
.logo-image-invert-dark {
  filter: invert(0.3);
}
.logo-image-invert-light {
  filter: invert(0.7);
}
</style>
